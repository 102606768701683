.App {
  text-align: center;
  font-family: 'Crete Round', serif;
  background-image: url('./static/backgrounds/compressed.jpg');
}

.container-fluid {
    padding-right:0;
    padding-left:0;
    margin-right:auto;
    margin-left:auto
 }

.App-header {
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #5569C3;
  width: 95%;
  display: flex;
  padding-top: 3.5em;
  padding-bottom: 57px;

}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.logo {
  filter: brightness(69%);
  opacity: .65;
}

.sitename {
  opacity: .88;
  font-size: 3.5em;
}

.tagline {
  font-size: 1.5em;
  font-family: 'Amatic SC', cursive;
  font-weight: bold;
  opacity: .88;

}

.instalogo {
  margin-top: 6em;
  fill: #ff770c;
  opacity: .88;
  width: 5em;
  height: 5em;
}

.contactform {
  margin-top: 4em;
  padding: 1em;
  font-size: 1.9em;
  text-shadow: 1px 2px 2px black;
  widows: 100%;

}

.bottom {
  align-self: center;
  display: flex;

}
.Info {

  font-family: 'Amatic SC', cursive;
  font-size: 3vh;
  color: #5569C3;
  border-color: #a94a00;
  width:100%;

}

.prices {
  height: 20em;
margin-top: 5em;
display: flex;
justify-content: space-around;
padding: 3em;
  /* @media screen and (max-width: 980px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: auto;
    padding: 3em;
  } */
}

.spacer {
  border-top: 3px solid grey;
  rotate: 90deg;
  width: 10em;
  margin-top: 4em;
  @media screen and (max-width: 980px) {
    rotate: 0deg;
  }
  /* position: relative;
  left: 120%;
  left: 20rem;
  bottom: 25%;
   */
}

@media screen and (max-width: 980px) {
  .prices {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: auto;
    padding: 3em;
  }
  .spacer {
    rotate: 0deg;
    width: 10em;
    /* margin-top: 4em; */
  }
}
