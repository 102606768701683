.Gallery {
  min-height: 100vh;
  max-width: 100%;
  /*see if this works*/
  overflow-y: scroll;
}

.work-item {

  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 30px;
  margin-right:auto;
  margin-left:auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 41%;
  height: 85%;
}

.images {
  width: 100%;
  height: 100%;
  box-shadow: 3px 5px 5px #656565;
  /*rounded edges*/

}
